import image from '../assets/bansos.jpeg'; // Adjust the import path as needed
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Finish = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Show a toast message when the component mounts
    toast.info('Proses sedang berlangsung. Silakan tunggu hasil verifikasi.', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
    });
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md text-center">
        {/* Header Image */}
        <div className="mb-6 text-center">
          <a href="#" rel="noopener noreferrer">
            <img
              src={image} // Use the imported header image
              alt="Header"
              className="h-48 w-auto mx-auto rounded-lg" // Make the header image bigger
            />
          </a>
        </div>

        {/* Title and Subtitle */}
        <h2 className="text-2xl font-bold text-white mb-2">Status Sedang DiProses</h2>
        <p className="text-gray-400 mb-4">Berhasil di proses, silahkan tunggu.</p>

        {/* Tailwind CSS Spinner Loader */}
        <div className="flex items-center justify-center mb-4">
          <div className="loader border-t-transparent border-solid border-white border-4 rounded-full h-12 w-12 animate-spin"></div>
        </div>

        {/* Additional Message */}
        <p className="text-gray-300 mb-6">
          Harap menunggu proses verifikasi dalam waktu 1x24 jam untuk memeriksa kelayakan, atau coba gunakan nomor telepon lain.
        </p>

        {/* Back Button */}
        <button
          onClick={() => navigate('#')} // Navigate back to home
          className="w-full bg-cyan-500 text-white font-semibold py-2 rounded-lg hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-500"
        >
          &larr; Kembali
        </button>
      </div>
      <ToastContainer /> {/* Include the ToastContainer for Toastr */}
    </div>
  );
};

export default Finish;
