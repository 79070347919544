// src/context/AuthContext.js

import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null); // JWT token
  const [phoneNumber, setPhoneNumber] = useState('');
  const [passwordNeeded, setPasswordNeeded] = useState(false);
  const [userInfo, setUserInfo] = useState(null); // To store user's full name

  const login = (token, phoneNumber) => {
    setToken(token);
    setPhoneNumber(phoneNumber);
  };

  const logout = () => {
    setToken(null);
    setPhoneNumber('');
    setPasswordNeeded(false);
    setUserInfo(null);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        logout,
        phoneNumber,
        setPhoneNumber,
        passwordNeeded,
        setPasswordNeeded,
        userInfo,
        setUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
